<!--数据-->
<template>
  <div class="container data-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-capitalize">数据</h1>
      </div>
    </div>
<!--    <div class="search-box">
      <div class="col-sm-12">
        <img src="../../assets/img/ico-01.png" alt="search">
        <b-form-input v-model="keyword" class="col-sm-6" type="search"
                      placeholder="筛选域名名单，例如，固废处理技术"></b-form-input>
        <b-button @click="toSearch()">搜索</b-button>
      </div>
    </div>-->
    <el-row :gutter="60">
      <el-col :span="12">
        <el-card shadow="hover" @click.native="go('/compare/wasteCompare')">
          <p>固废特征</p>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="hover" @click.native="go('/compare/techCompare')">
          <p>固废资源化技术</p>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import ui from "../../libs/ui";

export default {
  name: 'Data',
  props: {
    msg: String
  },
  data() {
    return {
      keyword: '',
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
    },
    go(path) {
      this.$router.push(path)
    },
    toSearch() {
      // eslint-disable-next-line no-debugger
      // debugger
      if (this.keyword === '') {
        ui.toast(this, ui.variant.primary, '请输入搜索内容')
        return;
      }
      if (this.$store.getters.token === '') {
        ui.toast(this, ui.variant.primary, '请先登录账号')
        return;
      }
      this.$router.push('/search/' + this.keyword)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../../assets/less/data.less";
</style>